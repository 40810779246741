import styles from "./Chapter.module.css";
import { useTextStyle } from "../../../../hooks/useTextStyle";

interface Props {
  text: string;
  darkMode: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export const Chapter = ({ text, onClick, darkMode, isSelected }: Props) => {
  const buttonClass = `${styles.button} ${darkMode && styles.buttonDarkMode} ${
    isSelected && styles.buttonSelected
  }`;

  const textStyle = useTextStyle(
    {
      large: "1.4em",
      normal: "1em",
      small: "0.9em",
    },
    !isSelected
  );

  return (
    <div className={styles.container}>
      <button
        style={textStyle}
        onClick={onClick}
        type="button"
        className={buttonClass}
      >
        {text}
      </button>
    </div>
  );
};
